<template>
  <div class="user-login">
    <div class="user-login__balance">
      <div class="user-login__balance--left">
        <p>{{ $formatAmountUnit(store.currentUser.balance, 1) }} K</p>
      </div>
      <div class="user-login__balance--right" @click.prevent="onNavDeposit()">
        <BaseImg src="/assets/images/components/desktop/pages/home/header/icon-deposit-brown.svg" alt="icon-deposit" />
        <p>NẠP</p>
      </div>
      <div class="user-login__balance--profile-header">
        <BaseImg src="/assets/images/common/avatar-pc-default.webp" class="img-hamber" alt="logo" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAppStore } from '~/store/app'
import BaseImg from '~/components/common/base-img.vue'
const { $pinia, $formatAmountUnit } = useNuxtApp()
const store = useAppStore($pinia)
const router = useRouter()

const onNavDeposit = () => {
  router.push('/account?tab=deposit')
}
</script>
<style scoped lang="scss" src="assets/scss/components/mobile/header/user-logged.scss" />
